@import "~@/assets/scss/variables";























































































































































.entity-table-head {
  &__label {
    margin-right: 4px;
  }
}
