@import "~@/assets/scss/variables";





































































































































































































































































































































































































































.entity-table {
  .l-table {
    &__empty {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &::v-deep {
    .table.b-table > thead > tr > th[aria-sort],
    .table.b-table > tfoot > tr > th[aria-sort] {
      background: none;

      &::after {
        content: normal;
      }
    }

    .dropdown-actions {
      .dropdown-menu {
        width: 208px;
      }

      .dropdown-item {
        color: inherit;
      }

      .dropdown-toggle {
        padding: 0 8px;
      }
    }

    .toggle-details {
      display: inline-flex;
      width: 16px;
      height: 16px;
      border: 2px solid var(--secondary);
      color: var(--secondary);
      background-color: #fff;
      border-radius: 16px;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: var(--secondary);
        color: #fff;
        transform: rotate(180deg);
      }
    }

    .dt-table__tr {
      &--selected {
        --row-bg: var(--entity-list-table-selected-even-row-bg);
        --odd-row-bg: var(--entity-list-table-selected-odd-row-bg);
      }

      &--locked {
        --row-bg: var(--entity-list-table-locked-entity-even-row-bg);
        --odd-row-bg: var(--entity-list-table-locked-entity-odd-row-bg);
      }

      &--deleted {
        --row-bg: var(--entity-list-table-deleted-entity-even-row-bg);
        --odd-row-bg: var(--entity-list-table-deleted-entity-odd-row-bg);
      }
    }
  }

  .th-label {
    display: flex;
    align-items: flex-start;

    .svg-icon {
      display: inline-block;
      flex-shrink: 0;
      margin-top: -2px;
      margin-right: 5px;
    }

    &__body {
      align-self: center;
    }

    &__text {
      display: inline;
      padding-top: 2px;
    }

    &__sort-icon {
      display: inline;
      white-space: nowrap;
      color: var(--table-sort-icon-color);

      .svg-icon {
        display: inline-block;
        margin-top: 0;
        margin-right: 0;
      }
    }

    .svg-icon + &__sort-icon {
      display: block;
      align-self: center;
      margin-left: 0;
    }
  }

  &__pagination {
    padding-top: 24px;
    /*border-top: 1px solid var(--line-color-main);*/
    display: flex;

    .pagination {
      margin-left: auto;
      margin-bottom: 0;
    }
  }

  /*.list-item {*/
  /*  &__action {*/
  /*    margin-left: 10px;*/

  /*    &:first-child {*/
  /*      margin-left: 0px;*/
  /*    }*/
  /*  }*/
  /*}*/

  .entity-table-head {
    &__label {
      margin-right: 4px;
    }
  }
}
