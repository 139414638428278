@import "~@/assets/scss/variables";











































































































































































































































.base-dropdown {
  $block: &;

  position: static;

  &.popup-relative {
    position: relative;

    #{$block}__popup {
      min-width: 100%;
    }
  }

  &__popup {
    color: var(--text-color);
    -webkit-box-shadow: 0 2px 4px var(--shadow-main);
    box-shadow: 0 2px 4px var(--shadow-main);
    z-index: 1000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: var(--border-radius, $border-radius);
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 4px;
    min-width: 160px;

    &::v-deep {
      .dropdown-divider {
        margin: 4px 0;
      }

      .dropdown-item {
        display: flex;
        padding: 8px 12px;
        text-align: left;

        &__icon {
          margin-right: 12px;
          height: 20px;
          display: flex;
          align-items: center;

          .svg-icon {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  &__menu-item--divider:last-child,
  &__menu-item--divider:has(+ &__menu-item--divider) {
    display: none;
  }
}
