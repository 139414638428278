@import "~@/assets/scss/variables";






















































@import "~spinkit/scss/spinkit.scss";

.v-spinner {
  --size: 24px;

  height: var(--size);
  width: var(--size);
}

.sk-circle {
  width: 100%;
  height: 100%;
  margin: 0 auto !important;
}

.sk-circle .sk-child::before {
  background-color: currentColor;
}
