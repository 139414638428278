@import "~@/assets/scss/variables";
































































































































































































































.items-clamp {
  display: block;
  position: relative;
  padding-right: 35px;
  transition: 0.2s ease-in-out;
  transition-property: max-height;

  &.clamp-lines,
  &.clamp-lines &__body,
  &--animation,
  &--animation &__body {
    overflow: hidden;
  }

  &.clamp-items:not([class*="clamp-items--show-all"])::v-deep {
    .clamp-item:not([class*="clamp-item--show"]) {
      display: none;
    }
  }

  &.clamp-lines &__body {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  &--flatten.clamp-lines &__body {
    display: block;
    -webkit-line-clamp: none;
    text-overflow: clip;
    overflow: visible;
  }

  &--expanded.clamp-lines &__body {
    display: block;
    -webkit-line-clamp: none;
  }

  &__trigger {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &--expandable &__trigger {
    display: block;
  }

  &__btn {
    padding: 2px 0 0;
    margin: 0;
    border: 0;
    outline: 0;
    background: none;
    color: var(--secondary, $secondary);
    line-height: 0;
    width: 32px;
    height: 21px;
    opacity: .7;
    transition: 0.2s ease-in-out;
    transition-property: opacity, color;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 1;
    }

    .svg-icon {
      display: block;
      width: 10px;
      height: 10px;
      transition: 0.2s ease-in-out;
      transition-property: transform, color;
    }
  }

  &--expanded &__btn {
    .svg-icon {
      transform: rotate(-180deg);
    }
  }

  &--collapsing &__btn {
    .svg-icon {
      transform: rotate(0);
    }
  }
}
