@import "~@/assets/scss/variables";



































































































































































































































































































.budget-slider {
  &__slider {
    margin: 50px 7px 32px;

    &:last-child {
      margin-bottom: 62px;
    }
  }

  &__value-tooltip {
    white-space: nowrap;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
  }

  &.vue-slider-disabled {
    cursor: default;
  }

  .bs-input {
    flex: 1;
    max-width: 130px;
    margin-right: 16px;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
    }

    &__label {
      font-weight: bold;
      margin-bottom: 5px;
    }

    &.select-currency {
      max-width: 210px;
    }
  }

  .vue-slider-dot-tooltip-top &__value-tooltip {
    bottom: 0;
  }

  .vue-slider-dot-tooltip-bottom &__value-tooltip {
    top: 0;
  }
}
