@import "~@/assets/scss/variables";


























































































































































































































































@import '~@/assets/scss/variables';

.v-tooltip {
  &__container {
    display: flex;
  }
}

.vt-link {
  display: block;
  text-decoration: none;

  &__text {
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.vt-actions,
.vt-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.vt-links {
  display: inline-block;
  margin-right: -5px;
  margin-bottom: -4px;

  &__item {
    margin-right: 5px;
    margin-bottom: 4px;

    .vt-link__text::after {
      content: ";";
    }

    &:last-child {
      //margin-right: 0;

      .vt-link__text::after {
        content: normal;
      }
    }
  }
}

.vt-actions {
  display: flex;
  align-items: center;

  &__item {
    font-weight: bold;
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    height: 12px;
    display: flex;
    align-items: center;

    &:first-child {
      border-left: none;
      margin-left: 0px;
      padding-left: 0px;
    }
  }
}

.vt {
  &__line {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &__list {
    list-style: none;
    margin: 0px;
    padding: 0px;

    &--actions {
      text-align: right;
    }
  }

  &__list--actions &__line {
    justify-content: flex-end;
  }

  &__text {
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__action {
    font-weight: bold;
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    height: 12px;
    display: flex;
    align-items: center;
  }
}
