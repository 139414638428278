@import "~@/assets/scss/variables";























































.field-user-selections {
  display: table;

  .selections-list {
    list-style: none;
    padding: 0;

    &__item {
      display: table;
      width: 100%;
      margin: 0 12px 12px 0;
    }

    &__name, &__link {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
