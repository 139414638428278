@import "~@/assets/scss/variables";
























































































































































































































.checkboxes-related {
  $block: &;

  &__columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 0;
    padding: 0;

    #{$block}.is-mobile & {
      display: block;
    }
  }

  &__column {
    min-width: 0;
    margin-right: 10px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-height: 250px;

    &:last-child {
      margin-right: 0;
    }
  }
}
