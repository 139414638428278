@import "~@/assets/scss/variables";














































@import '~@/assets/scss/variables';

.widget-empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 15px;

  &__image {
    margin-bottom: 20px;

    img {
      width: 300px;
      max-width: 100%;
    }
  }

  &__body {
    max-width: 300px;
    text-align: center;

    &::v-deep {
      p {
        font-family: var(--font-heading, $font-heading);
        font-size: 18px;
        line-height: 21px;
      }

      a {
        color: var(--secondary);
        font-size: 16px;
        line-height: 19px;
      }

      .create-link {
        display: inline-flex;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 22px;
        font-size: 14px;
        margin-top: 24px;

        &__icon {
          margin-right: 8px;
          display: flex;
          align-items: center;
          height: 22px;

          .svg-icon {
            --size: 16px;

            display: block;
          }
        }
      }
    }
  }
}
