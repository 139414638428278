@import "~@/assets/scss/variables";



























































































































































.entity-table-dropdown-actions-popup,
.entity-table  {
  .action-link {
    &--icon-link {
      color: var(--secondary);
      padding: 3px;
      display: block;

      &:hover {
        color: var(--link-secondary-hover-color);
      }
    }

    &--warning {
      color: var(--icon-del-color);

      &:hover {
        color: var(--icon-del-hover-color);
      }
    }

    &--secondary, &--secondary:hover {
      color: var(--secondary);
    }

    &.disabled, &.disabled:hover {
      color: var(--text-muted);
    }
  }
}
