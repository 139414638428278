@import "~@/assets/scss/variables";

















































































































































































































































.cr-item {
  $block: &;

  &__info {
    margin-left: auto;
    margin-top: -1px;
  }

  &__checkbox-area {
    --bg-color: transparent;

    display: flex;
    padding: 9px 10px;
    position: relative;
    margin-bottom: -1px;
    font-size: 16px;
    line-height: 23px;
    background-color: var(--bg-color);
    cursor: pointer;
    color: var(--text-color);

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2;
      border-bottom: 1px solid var(--checkboxes-related-active-bg);
    }

    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 20px;
      padding: 6px 10px;

      &::before {
        content: normal;
      }
    }

    #{$block} > &:not(.bar):hover {
      --bg-color: var(--checkboxes-related-hover-bg);
    }

    #{$block}.some-checked > & {
      --bg-color: var(--secondary-bg);
    }

    #{$block}.active > & {
      z-index: 1;

      @include media-breakpoint-up(sm) {
        --bg-color: var(--checkboxes-related-active-bg);

        &:hover {
          --bg-color: var(--checkboxes-related-hover-bg);
        }
      }
    }

    #{$block}__name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    #{$block}__result-badges {
      // min-width: 15px;
      text-align: right;
      //margin-left: 7px;
      display: flex;
      align-items: center;

      .badge {
        display: block;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          height: 23px;
          padding-top: 0;
          padding-bottom: 0;
          min-width: 22px;
          border-radius: 15px;
        }
      }
    }

    #{$block}__result-badges,
    #{$block}__country-badges {
      display: flex;
      align-items: center;
      gap: 0 2px;

      .badge {
        margin: 0;
      }
    }

    #{$block}:not(.is-exclude) > & #{$block}__result-badges {
      .badge {
        background: var(--text-shaded);
      }
    }

    #{$block}:not(.is-exclude).every-checked > & #{$block}__result-badges {
      .badge {
        background: var(--secondary);
      }
    }

    #{$block}.disabled > &,
    #{$block}.disabled:hover > & {
      --bg-color: var(--secondary-bg);
    }

    #{$block}__children__item > & {
      padding-left: 22px;
    }

    &__content {
      display: flex;
      min-width: 0;
      flex: 1;
      gap: 0 7px;

      &__body {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        min-width: 0;
        gap: 0 7px;
      }
    }

    .base-switcher {
      margin-right: 7px;
      padding: 0;
      background: none;
    }

    .custom-control {
      padding: 0;
      margin-right: 4px;

      .custom-control-label {
        padding-left: 20px;

        &::before,
        &::after {
          top: 2px;
          left: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-right: 5px;

        .custom-control-label {
          padding-left: 25px;
        }
      }
    }
  }

  &__arrow {
    display: none;
    align-items: center;
    //margin-left: 7px;
    transition: 0.3s ease-in-out;
    transition-property: background, border;

    .svg-icon {
      height: 18px;
      width: 18px;
    }
  }

  &.is-folder {
    &:first-child > #{$block}__checkbox-area {
      border-top: 0;
    }

    &:hover > #{$block}__checkbox-area,
    &.open > #{$block}__checkbox-area {
      --text-color: var(--primary);
    }

    &.open > #{$block}__checkbox-area {
      #{$block}__arrow {
        transform: rotate(180deg);
      }
    }

    & > #{$block}__checkbox-area {
      --text-color: var(--text-shaded);
      --toggle-color: var(--secondary);

      border-top: 1px solid var(--line-color-main);
      transition: 0.3s ease-in-out;

      #{$block}__name {
        font-weight: 500;
        font-size: 14px;
      }

      #{$block}__arrow {
        display: flex;
      }
    }
  }

  &__disabled {
    display: none;
    margin-top: 8px;

    &__title {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 5px;
      color: var(--checkboxes-related-item-disabled-title-color);
    }

    &__text {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &.disabled {
    cursor: default;

    .custom-control-label::before {
      border-color: var(--checkboxes-related-item-disabled-color);
    }

    &.active .custom-control-label::before {
      border-color: var(--checkboxes-related-item-active-disabled-color);
    }
  }

  &.disabled &__name {
    color: var(--checkboxes-related-item-disabled-color);
  }

  &.disabled.active &__disabled {
    display: block;
  }

  &__checkbox.custom-checkbox {
    margin: 0;
  }

  &__check-mark {
    flex-shrink: 0;

    .svg-icon {
      --size: 12px;
    }
  }

  &__children {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .checkboxes-related.is-mobile &.has-children:not(.is-folder) &__arrow {
    display: flex;

    .svg-icon {
      transform: rotate(270deg);
    }
  }

  .checkboxes-related.view-only & {
    &.has-children {
      #{$block}__checkbox-area {
        --bg-color: transparent;
        --text-color: var(--link-disable-color);

        &:hover {
          --bg-color: transparent;
        }
      }

      &.active > #{$block}__checkbox-area,
      & > #{$block}__checkbox-area:hover {
        --bg-color: var(--secondary-bg);
      }

      &.some-checked #{$block}__checkbox-area {
        --text-color: var(--color-text-main);
      }

      &:not(.is-folder) {
        #{$block}__arrow {
          display: flex;

          .svg-icon {
            transform: rotate(270deg);
          }
        }
      }

      &.is-folder {
        --text-color: var(--color-text-main);

        &:not(.some-checked) {
          --text-color: var(--text-shaded);
        }
      }
    }

    &:not(.has-children) {
      --bg-color: transparent;

      #{$block}__checkbox-area:hover {
        --bg-color: transparent;
      }

      &.some-checked #{$block}__checkbox-area {
        --bg-color: var(--secondary-bg);
      }

      &:not(.some-checked) #{$block}__checkbox-area {
        color: var(--link-disable-color);
      }

      #{$block}__checkbox-area {
        cursor: default;
      }
    }

    &__checkbox {
      display: none;
    }

    .badge {
      font-size: 12px;
      line-height: 14px;
      min-width: 14px;
      height: 14px;
      padding: 0 4px;
    }
  }
}
