@import "~@/assets/scss/variables";



































































.field-user {
  display: flex;

  &__avatar {
    margin-right: 10px;
  }
}

.user-info {
  $block: &;

  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--text-color);

  &:is(a):hover {
    color: var(--link-hover-color);
  }

  &__email {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-shaded);
    transition: color .3s ease-in-out;

    a#{$block}:hover & {
      color: var(--link-hover-color);
    }
  }
}
