@import "~@/assets/scss/variables";

























































































.checked-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.checked-item {
  &::v-deep {
    .custom-radio, .custom-checkbox {
      display: flex;
      margin: 0;
      padding: 0;
    }
    .custom-control-label {
      display: flex;
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      padding: 10px;

      b {
        font-weight: bold;
      }
    }

    &.active .custom-control-label,
    &:not(.disabled) .custom-control-label:hover {
      background-color: var(--user-select-bg, $user-select-bg);
    }

    .custom-checkbox .custom-control-label {
      padding-right: 32px;

      &::before, &::after {
        position: absolute;
        left: auto;
        right: 8px;
        top: calc(50% - 8px);
      }
    }

    .custom-radio .custom-control-label {
      &::before,
      &::after {
        content: normal;
      }
    }

    .c-avatar {
      --size: 28px;
    }
  }

  &__avatar {
    flex: none;
    margin-right: 7px;
  }

  &__name {
    min-width: 0;
    padding-top: 1px;
    flex: 1;
    align-self: center;
  }

  &.active .c-avatar__img {
    color: var(--avatar-active-color, $avatar-active-color);
    background: var(--avatar-active-bg, $avatar-active-bg);
    border-color: var(--avatar-active-border-color, $avatar-active-border-color);
  }
}

.user-option {
  display: flex;
  align-items: center;

  &__avatar {
    flex-shrink: 0;
    margin-right: 8px;
  }

  /*&__name {
    font-weight: 500;
  }*/

  &__email {
    font-size: 12px;
    line-height: 14px;
  }

  &::v-deep {
    b {
      color: var(--text-color);
    }
  }
}
