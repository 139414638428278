@import "~@/assets/scss/variables";






















































































































































.ci-field-shortened {
  display: flex;

  &__text {
    flex: 1;
    margin-right: 6px;
  }
  &__link {
    flex: none;

    a {
      color: var(--secondary);
    }
  }
}

.info-title {
  font-weight: bold;
}

.info-field {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 5px;
  }
}
