@import "~@/assets/scss/variables";







































.info-badge {
  $block: &;

  --variant-color: var(--text-color);

  &__icon {
    --size: 16px;
  }

  &--secondary {
    --variant-color: var(--secondary);
  }

  &--warning {
    --variant-color: var(--warning);
  }

  &--label {
    #{$block}__text {
      background-color: var(--variant-color);
      color: #fff;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0 2px;
      display: inline;
    }
  }

  &--icon {
    color: var(--variant-color);
  }
}
