@import "~@/assets/scss/variables";







































































.checkboxes-related-column {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1 1;
  }

  &:not(.active-col) &__list {
    max-height: 250px;
    padding: 0 0 5px;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }

  .checkboxes-related.is-mobile & {
    display: none;
    margin-right: 0;

    &:not(:first-child) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: 50;
      padding: 7px 15px 15px;
      display: flex;
      flex-direction: column;
      transform: translateX(-100%);
      transition: transform 0.3s;
      min-height: auto;
      overflow-y: auto;
      -webkit-overflow-scrolling: auto;

      &.active-col {
        transform: translateX(0);
      }
    }

    //.dashboard-container &:not(:first-child) {
    //  top: 55px;
    //  height: calc(100% - 55px);
    //}

    //.search-panel &:not(:first-child) {
    //  top: 0;
    //  height: calc(100% - 15px);
    //}

    &:not(:first-child) &__body {
      min-height: 0;
      max-height: none;
      flex: 1 1;
    }

    &:first-child {
      display: block;

      .checkboxes-related-column__mob-nav {
        display: none;
      }
    }
  }

  &__mob-nav {
    border-bottom: 1px solid var(--line-color-main);
    color: var(--text-color);
    padding-bottom: 2px;
    margin-bottom: 5px;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    .crl-back-btn {
      font-family: var(--font-heading, $font-heading);
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 10px 0;
      cursor: pointer;
      display: inline-flex;
      align-items: center;

      &:not([href]):not([tabindex]):hover {
        color: var(--secondary, $secondary);
      }

      &::before {
        content: '';
        display: block;
        color: var(--secondary, $secondary);
        margin-top: -2px;
        margin-right: 5px;
        width: 8px;
        height: 8px;
        border: solid currentColor;
        border-width: 0 0 1px 1px;
        transform: rotate(45deg);
      }
    }
  }
}
