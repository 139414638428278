@import "~@/assets/scss/variables";
































































































@import '~@/assets/scss/variables';

.loading {
  max-height: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &--overlay {
    position: absolute;
    height: auto;
    min-height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--loading-overlay-bg);
    z-index: 1;
    display: flex;
    align-items: center;
  }

  &--spinner-at-top {
    align-items: flex-start;
  }

  &.opacity {
    background-color: var(--loading-overlay-bg-opacity);
  }

  .loading-inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px auto 10px;
  }

  .loading-status {
    width: 100%;
    margin: 15px auto 0px;
    padding: 0px 15px;
  }

  &::v-deep {
    .sk-circle {
      margin: 0 auto;
    }
  }
}
