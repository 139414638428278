@import "~@/assets/scss/variables";













































































































































































































































































































































































































































































































































































































































































.el-show-more-link {
  font-family: var(--font-heading, $font-heading);
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--secondary);
}
