@import "~@/assets/scss/variables";































































.c-avatar {
  --size: 32px;

  width: var(--size);
  height: var(--size);
  color: var(--user-avatar-color);
  font-family: var(--font-heading, $font-heading);
  font-weight: bold;
  font-size: calc(var(--size) / 1.8);
  line-height: 1;
  flex-shrink: 0;

  :where(a, button):hover & {
    color: var(--user-avatar-hover-color);
  }

  &__img {
    margin: 0;
    width: 100%;
    height: 100%;
    background: var(--user-avatar-bg);
    transition: 0.3s;
    transition-property: color, background, border-color;
    border: 1px solid var(--user-avatar-border-color);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
