@import "~@/assets/scss/variables";



























































































.checkboxes-columns {
  @include media-breakpoint-up(sm) {
    columns: 2;
    column-gap: 16px;
  }

  &.view-only::v-deep {
    .custom-control-label {
      color: var(--text-shaded);
      pointer-events: none;

      &::before, &::after {
        display: none;
      }
    }
  }

  &.view-only {
    &--show-as-checked::v-deep .custom-control-label,
    &::v-deep .custom-control-input:checked ~ .custom-control-label {
      color: var(--color-text-main);

      .checkbox-checked {
        display: block;
        align-self: flex-start;
      }

      .badge {
        font-size: 12px;
        line-height: 14px;
        min-width: 14px;
        height: 14px;
        padding: 0 4px;
      }
    }
  }

  &__item {
    break-inside: avoid;
  }
}

.checkbox-content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  &__name {
    flex: 1;
  }

  &__badges {
    flex-shrink: 0;
  }
}

.checkbox-checked {
  display: none;
  flex-shrink: 0;

  .svg-icon {
    --size: 12px;
  }
}
