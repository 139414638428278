@import "~@/assets/scss/variables";






















































































.selections-field {
  display: flex;
}

.selections-list {
  list-style: none;
  padding: 0;
  margin-right: auto;
  margin-bottom: 0;

  &__item {
    display: flex;
    margin-bottom: 8px;
  }

  &__name {
    margin-right: 4px;
  }

  &__edit-link {
    color: var(--secondary);

    .svg-icon {
      --size: 16px;
    }
  }

  &__view-link {
    color: var(--secondary);

    .svg-icon {
      --size: 12px;
    }
  }
}

.selection-add-link {
  margin-right: 4px;
  color: var(--secondary);

  .svg-icon {
    --size: 16px;
  }
}
