@import "~@/assets/scss/variables";

























































































.collapsible-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &--no-content-slots::v-deep {
    .item-info__icon {
      display: none;
    }
  }
}
