$font-main: "Roboto", Helvetica, Arial, sans-serif;
$font-heading: "Roboto Condensed", Helvetica, Arial, sans-serif;

$border-radius: 4px;

$brand-denim: #13314d;
$brand-denim-1: #586e81;
$brand-denim-2: #8292a1;
$brand-denim-3: #acb6c0;
$brand-denim-4: #c7cfd5;
$brand-denim-5: #e3e7ea;
$brand-denim-6: #f7f8f9;

$brand-pear: #94991c;
$brand-pear-1: #bbc200;
$brand-pear-2: #dade91;
$brand-pear-3: #eff1d0;
$brand-pear-4: #7b8100;
$brand-pear-5-cta: #777916;
$brand-pear-6-cta: #666713;

$brand-graphite: #61666b;
$brand-graphite-1: #F4F5F5;

$brand-sage: #a9ad92;
$brand-sage-1: #E2E6E3;
$brand-sage-2: #F4F5F1;
$brand-sage-3: #777A67;

$brand-mulberry: #651e56;
$brand-mulberry-1: #c4a8be;
$brand-mulberry-2: #f3eef2;
$brand-mulberry-3: #804D74;
$brand-mulberry-4: #38002D;

$brand-eco: #55c980;

$brand-paris-green-1: #99dfb3;
$brand-paris-green-2: #ddf4e6;
$brand-paris-green-3: #4aa76c;

$brand-info: #3392cc;
$brand-info-dark: #0b5885;
$brand-error: #b2412f;

$brand-white: #fff;

$primary: $brand-denim;
$secondary: $brand-pear-5-cta;
$secondary-dark: $brand-pear-6-cta;
$secondary-light: $brand-pear-1;
$success: $brand-paris-green-3;
$info: $brand-info;
$warning: $brand-mulberry;
$danger: $brand-error;
$danger-secondary: $brand-mulberry;

$light: $brand-denim-5;
$dark: $brand-denim;
$eco: $brand-eco;

$main-bg: $brand-white;
$secondary-bg: $brand-denim-6;
$active-bg: $brand-pear-3;
$success-bg: $brand-paris-green-2;
$text-color: $primary;
$text-secondary-color: $brand-graphite;
$text-inverse-color: $brand-white;
$text-muted: $brand-denim-1;
$text-shaded: $brand-denim-1;
$text-shaded-secondary: $brand-pear-5-cta;
$text-em-inactive: $brand-sage;
$link-color: $text-color;
$link-hover-color: $brand-pear-6-cta;
$link-disable-color: $brand-denim-3;
$link-secondary-hover-color: $brand-pear-5-cta;
$link-warning-hover-color: $brand-mulberry-3;

$body-bg: $secondary-bg;
$body-color: $text-color;

$shadow-main: rgba($brand-denim, .1);
$shadow-light: rgba($brand-denim-1, .15);

$line-color-main: $brand-denim-5;
$line-color-dark: $brand-denim-4;
$line-color-light: $brand-denim-6;

$modal-backdrop-color: rgba(darken($primary, 15%), 0.7);

$loading-overlay-bg: $brand-white;
$loading-overlay-bg-opacity: rgba($brand-white, .5);
$loading-overlay-bg-sec: rgba($secondary-bg, .5);

// Sidebar
$sidebar-shadow: rgba($primary, .2);
$sidebar-color: $brand-denim-4;
$sidebar-bg: $primary;
$sidebar-header-bg: transparent;
$sidebar-link-color: $sidebar-color;
$sidebar-link-hover-color: $brand-pear-1;
$sidebar-line-color: $brand-denim-2;
$nav-title-color: $brand-denim-3;
$sidebar-nav-link-color: $sidebar-color;
$sidebar-nav-link-hover-color: $sidebar-color;
$sidebar-nav-link-hover-bg: rgba($secondary, .1);

$sidebar-nav-link-active-color: $brand-pear-1;
$sidebar-nav-submenu-link-active-color: #fff;
$sidebar-nav-link-active-bg: rgba($brand-pear-1, .15);

$sidebar-nav-menu-title-color: $sidebar-color;
$sidebar-nav-menu-title-active-color: $brand-white;

$sidebar-nav-submenu-link-color: $sidebar-link-color;
$sidebar-nav-submenu-line-color: #455d36;
$sidebar-nav-submenu-line-active-color: $brand-pear-1;
$sidebar-nav-submenu-icon-color: $brand-denim-4;
$sidebar-nav-submenu-active-icon-color: #fff;

$sidebar-nav-dropdown-color: $sidebar-nav-link-color;
$sidebar-nav-dropdown-bg: $sidebar-bg;
$sidebar-nav-dropdown-indicator-color: $brand-denim-3;
$sidebar-nav-dropdown-shadow: 0 0 4px rgba(184, 184, 184, 0.2);
$sidebar-nav-dropdown-title-color: rgba($sidebar-color, .6);

$sidebar-nav-badge-bg: $brand-pear-1;
$sidebar-nav-badge-color: $primary;
$sidebar-nav-badge-border-color: $brand-pear-1;
$sidebar-nav-badge-shadow: 0 0 0 1px rgba($secondary, .1);

$sidebar-minimizer-color: $brand-pear-1;
$sidebar-minimizer-hover-color: $brand-white;

$sidebar-setup-assistant-color: $primary;
$sidebar-setup-assistant-bg: $brand-pear-1;
$sidebar-setup-assistant-val-color: $primary;
$sidebar-setup-assistant-progress-bg: $brand-denim-1 linear-gradient(135deg, #{$brand-denim-1} 0%,#{$brand-denim-1} 33.32%,#76834f 33.33%,#76834f 66.65%,#{$brand-denim-1} 66.66%,#{$brand-denim-1} 100%) 0 0/14px 8px repeat-x;
$sidebar-setup-assistant-progress-active: $primary;
$sidebar-setup-assistant-button-bg: $primary;
$sidebar-setup-assistant-button-active-bg: lighten($primary, 7.5%);

$sidebar-select-client-bg: rgba($brand-denim-1, .5);

// Header
$header-bg: $body-bg;
$header-bg-sm: $primary;
$header-color: $primary;
$header-color-sm: $brand-white;
$header-line-color: $line-color-main;
$header-notif-num-badge-bg: $secondary;
$header-notif-num-badge-color: $brand-white;
$header-nav-lnk-hover: $secondary;

$user-avatar-color: $brand-denim;
$user-avatar-hover-color: $secondary;
$user-avatar-bg: $brand-denim-5;
$user-avatar-hover-bg: $brand-pear-3;
$user-avatar-border-color: $brand-denim-2;
$user-avatar-border-hover-color: $secondary;
$current-user-avatar-color: $brand-pear-6-cta;
$current-user-avatar-bg: $brand-pear-3;
$current-user-avatar-border-color: $brand-pear;
$inactive-user-avatar-color: $brand-denim-3;
$inactive-user-avatar-bg: $brand-white;
$inactive-user-avatar-border-color: $brand-denim-3;

$avatar-color: $brand-denim-1;
$avatar-active-color: $brand-denim;
$avatar-bg: $brand-denim-5;
$avatar-active-bg: $brand-denim-4;
$avatar-border-color: $brand-denim-2;
$avatar-active-border-color: $brand-denim;

$navbar-toggler-color: $primary;
$navbar-toggler-color-sm: $header-color-sm;
$navbar-toggler-hover-color: $secondary;
$navbar-toggler-hover-color-sm: $secondary;

$breadcrumb-color: $brand-denim-1;
$breadcrumb-link-color: $secondary;
$breadcrumb-separator-color: rgba($primary, .5);

// C-Card
$card-bg: $main-bg;
$card-shadow-color: $shadow-main;
$card-shadow-light-color: $shadow-light;
$card-border-color: $line-color-main;
$card-border-color-dark: $brand-denim-4;
$card-collapse-icon-color: $brand-pear-5-cta;
$card-title-color: $brand-denim-1;
$card-title-brackets-text-color: $brand-sage;

// Tabs
$tabs-main-bg: $brand-white;
$tabs-main-shadow: 0 2px 4px $shadow-main;
$tabs-main-border-color: $card-border-color;
$tabs-main-border: 1px solid $tabs-main-border-color;

$tab-link-color: $brand-denim-1;
$tab-link-bg: $tabs-main-bg;
$tab-link-border-color: $tabs-main-border-color;
$tab-link-shadow: 0 0 3px $shadow-main;
$tab-link-hover-color: $secondary;
$tab-link-hover-bg: $secondary-bg;

$tab-link-active-color: $primary;
$tab-link-active-bg: $tabs-main-bg;
$tab-link-active-border-top: 4px solid $brand-pear-1;
$tab-link-active-shadow: 0 -2px 4px -2px $shadow-main;

$tabs-line-main-border-color: $brand-pear-1;
$tabs-line-link-border-color: $brand-pear-1;

// -- ca-tabs
$ca-tabs-mob-bg: $secondary-bg;
$ca-tabs-mob-border-active: $brand-denim-4;
$ca-tabs-mob-icon: $secondary;

// Forms

$focus-shadow-color: rgba($primary, .12);
$active-shadow-color: rgba($primary, .3);

// Buttons
$btn-color: $brand-white;
$btn-primary-active-bg: darken($primary, 7.5%);
$btn-secondary-active-bg: darken($secondary, 7.5%);
$btn-secondary-light-active-bg: darken($secondary-light, 7.5%);
$btn-success-active-bg: darken($success, 7.5%);
$btn-info-active-bg: darken($info, 7.5%);
$btn-warning-active-bg: darken($warning, 7.5%);
$btn-danger-active-bg: darken($danger, 7.5%);

$btn-ghost-primary-color: $brand-denim;
$btn-ghost-primary-hover-color: $brand-denim-1;
$btn-ghost-primary-hover-bg: $brand-denim-5;
$btn-ghost-primary-active-bg: darken($brand-denim-5, 7.5%);

$btn-ghost-secondary-color: $brand-pear-5-cta;
$btn-ghost-secondary-hover-color: $brand-pear-6-cta;
$btn-ghost-secondary-hover-bg: $brand-pear-3;
$btn-ghost-secondary-active-bg: darken($brand-pear-3, 7.5%);

$btn-ghost-warning-color: $brand-mulberry;
$btn-ghost-warning-hover-color: $brand-mulberry-4;
$btn-ghost-warning-hover-bg: $brand-mulberry-2;
$btn-ghost-warning-active-bg: darken($brand-mulberry-2, 7.5%);

$secondary-focus-shadow-color: rgba($secondary, .12);
$secondary-active-shadow-color: rgba($secondary, .3);
$success-focus-shadow-color: rgba($success, .12);
$success-active-shadow-color: rgba($success, .3);
$info-focus-shadow-color: rgba($info, .12);
$info-active-shadow-color: rgba($info, .3);
$warning-focus-shadow-color: rgba($warning, .12);
$warning-active-shadow-color: rgba($warning, .3);
$danger-focus-shadow-color: rgba($danger, .12);
$danger-active-shadow-color: rgba($danger, .3);

//labels
$label-main-color: $text-color;

// Inputs
$input-bg: $brand-white;
$input-date-bg: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 10 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.4922-0.0078125a0.50005 0.50005 0 0 0-0.49219 0.50781v1.5h-2.5c-0.82235 0-1.5 0.6777-1.5 1.5v11c0 0.8224 0.67765 1.5 1.5 1.5h13c0.82235 0 1.5-0.6776 1.5-1.5v-11c0-0.8223-0.67765-1.5-1.5-1.5h-2.5v-1.5a0.50005 0.50005 0 0 0-0.50781-0.50781 0.50005 0.50005 0 0 0-0.49219 0.50781v1.5h-6v-1.5a0.50005 0.50005 0 0 0-0.50781-0.50781zm-2.9922 3.0078h2.5v1a0.50005 0.50005 0 1 0 1 0v-1h6v1a0.50005 0.50005 0 1 0 1 0v-1h2.5c0.28565 0 0.5 0.2144 0.5 0.5v11c0 0.2857-0.21435 0.5-0.5 0.5h-13c-0.28565 0-0.5-0.2143-0.5-0.5v-11c0-0.2856 0.21435-0.5 0.5-0.5zm7.9922 2.9922a0.50005 0.50005 0 0 0-0.074219 0.0078125h-2.918a0.50005 0.50005 0 0 0-0.5 0.5v2.5h-2.5a0.50005 0.50005 0 0 0-0.5 0.5v3a0.50005 0.50005 0 0 0 0.5 0.5h5.9199a0.50005 0.50005 0 0 0 0.16211 0h2.918a0.50005 0.50005 0 0 0 0.5-0.5v-2.9199a0.50005 0.50005 0 0 0 0-0.16211v-2.918a0.50005 0.50005 0 0 0-0.5-0.5h-2.9082a0.50005 0.50005 0 0 0-0.099609-0.00781zm-2.4922 1.0078h2v2h-2zm3 0h2v2h-2zm-6 3h2v2h-2zm3 0h2v2h-2zm3 0h2v2h-2z' style='fill:%23b9acbb'/%3E%3C/svg%3E");
$input-bg-disabled: $brand-denim-6;
$input-color: $primary;
$input-color-disabled: $text-muted;
$input-placeholder-color: $brand-denim-1;
$input-placeholder-hover-color: $brand-denim-2;
$input-border-color: $brand-denim-3;
$input-focus-border-color: $brand-denim-2;
$input-border-error-color: $danger;
$input-border-success-color: $success;
$input-invalid-bg: $brand-mulberry-2;
$input-invalid-border-color: $brand-mulberry-1;
$input-invalid-focus-border-color: $brand-mulberry;

$dropdown-select-hover-bg: $brand-denim-5;
$dropdown-select-append-color: $brand-denim-2;
$dropdown-link-arrow-color: $secondary;
$dropdown-link-dots-active-color: $brand-pear-1;
$dropdown-link-dots-color: $brand-denim-3;

// Dropdown select item
$dropdown-select-item-color: $text-shaded;
$dropdown-select-item-disable-color: $brand-denim-4;
$dropdown-select-item-hover-color: $text-color;
$dropdown-select-item-hover-bg: $brand-denim-5;
$dropdown-select-item-active-color: $text-inverse-color;
$dropdown-select-item-active-bg: $primary;
$dropdown-select-check-active-color: $text-color;
$dropdown-select-check-active-bg: $secondary-bg;
$dropdown-select-icon-hover-color: $secondary;

// Selects
$select-arrow-bg: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 10 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0.74958 0.0293a0.80261 0.80261 0 0 0-0.52344 1.3593l4.0059 4.2696c0.19594 0.209 0.47848 0.3418 0.7832 0.3418 0.30472 0 0.58726-0.1328 0.7832-0.3418l4.0059-4.2696a0.80278 0.80278 0 1 0-1.1699-1.0996l-3.6191 3.8594-3.6191-3.8594a0.80261 0.80261 0 0 0-0.64648-0.2597z' style='fill:%23b9acbb'/%3E%3C/svg%3E");
$select-selected-color: $input-color;
$select-selected-bg: $brand-denim-4;
$select-highlight-color: $brand-white;
$select-highlight-bg: $primary;
$select-highlight-bg-2: $brand-denim-5;
$select-combo-btn-line-color: $line-color-main;

//slider
$slider-rail-color: $brand-denim-4;
$slider-process-color: $secondary;
$slider-dot-handle-color: $secondary;
$slider-dot-handle-shadow: rgba($secondary, .5);

// Switcher
$switcher-track-color: $brand-denim-4;
$switcher-dot-color: $primary;
$switcher-on-track-color: $brand-pear-2;
$switcher-on-dot-color: $secondary;
$switcher-exclude-on-track-color: $brand-mulberry-1;
$switcher-exclude-on-dot-color: $warning;
$switcher-enabler-active-color: $brand-pear-4;
$switcher-focus-track-color: $brand-denim-3;
$switcher-on-focus-track-color: $brand-pear-1;
$switcher-on-focus-dot-color: $brand-pear-4;

$base-switcher-track-color: $brand-denim-1;
$base-switcher-track-checked-color: $brand-pear;
$base-switcher-track-checked-2-color: $brand-mulberry;
$base-switcher-track-indeterminate-color: $brand-denim-1;
$base-switcher-handle-color: $brand-white;
$base-switcher-handle-border: 1px solid;
$base-switcher-handle-unchecked-border-color: $brand-denim-1;
$base-switcher-handle-unchecked-bg: $brand-white;
$base-switcher-handle-checked-border-color: $brand-pear;
$base-switcher-handle-checked-bg: $brand-pear;
$base-switcher-handle-checked-focus-shadow-color: rgba($brand-pear, .25);
$base-switcher-handle-checked-2-border-color: $brand-mulberry;
$base-switcher-handle-checked-2-bg: $brand-mulberry;
$base-switcher-handle-checked-2-focus-shadow-color: rgba($brand-mulberry, .15);
$base-switcher-handle-indeterminate-bg: $brand-denim-1;
$base-switcher-handle-indeterminate-border-color: $brand-denim-1;
$base-switcher-handle-indeterminate-focus-shadow-color: $focus-shadow-color;

// Card List
$entity-list-header-label-color: $text-muted;
$entity-list-header-line-color: $line-color-main;
$entity-list-table-odd-row-bg: $brand-denim-6;
$entity-list-table-subrows-even-row-bg: rgba($brand-denim-1, .05);

$entity-list-table-deleted-entity-odd-row-bg: #FDF9FC;
$entity-list-table-deleted-entity-even-row-bg: #F7F1F6;
$entity-list-card-deleted-entity-bg: #FDF9FC;
$entity-list-card-content-deleted-entity-bg: #F7F1F6;

$entity-list-table-locked-entity-odd-row-bg: #F2F3F3;
$entity-list-table-locked-entity-even-row-bg: #F7F8F8;
$entity-list-card-locked-entity-bg: #F7F8F8;
$entity-list-card-content-locked-entity-bg: #F2F3F3;

$entity-list-table-selected-odd-row-bg: #F5F6ED;
$entity-list-table-selected-even-row-bg: #FAFBF1;

$entity-list-selected-card-bg: #FAFBF1;
$entity-list-selected-card-content-bg: #F5F6ED;


$entity-list-header-btn-color: $secondary;
$entity-list-header-btn-hover-color: $brand-pear-6-cta;
$entity-list-header-btn-hover-bg: $brand-pear-3;
$entity-list-header-btn-active-color: $btn-color;
$entity-list-header-btn-active-bg: $secondary;
$entity-list-total-num-color: $secondary;
$entity-list-button-collapse-icon-color: $brand-pear-1;
$entity-list-bulk-operation-btn-border-color: $brand-denim-3;
$entity-list-bulk-operation-btn-hover-border-color: $brand-denim-1;
$entity-list-bulk-operation-btn-color: $brand-denim-1;
$entity-list-bulk-operation-btn-hover-color: $brand-denim;
$entity-list-bulk-operation-btn-danger-border-color: $brand-mulberry-1;
$entity-list-bulk-operation-btn-danger-hover-border-color: $brand-mulberry;
$entity-list-bulk-operation-btn-danger-color: $brand-mulberry;

// Entity card
$entity-card-category-color: $text-shaded;
$entity-card-header-text-color: $text-muted;

$table-td-color: $text-color;
$table-th-color: $text-color;

$donut-stages-fill: $brand-denim-4;
$donut-stages-active-fill: $primary;

$info-list-border-color: $line-color-main;
$info-list-odd-bg: $brand-denim-6;
$info-list-label-color: $text-muted;
$info-list-uncertainty: $brand-mulberry;

$exp-update-closed-color: $brand-mulberry;
$widget-not-found: $brand-denim-3;

// Fields
$field-activity-notes-link-color: $brand-denim-1;
$field-activity-notes-link-hover-color: $primary;
$field-contact-status-link-color: $primary;
$field-contact-status-link-hover-color: $secondary;
$field-activity-notes-activity-overdue-missed: $brand-mulberry;
$field-roles-color: $brand-sage-3;
$field-companies-on-project-header: $brand-denim-5;
$field-companies-on-project-even-bg: $entity-list-table-odd-row-bg;
$field-companies-on-project-odd-bg: #F1F3F5;

// Card
$card-item-bg: $main-bg;
$card-item-border: $brand-denim-4;
$card-item-active-border: $brand-denim-3;
$card-item-shadow-color: rgba($primary, 0.1);
$card-item-footer-bg: $secondary-bg;
$card-item-footer-border-color: $line-color-main;
$card-item-expand-button-bg: $brand-denim-3;
$card-item-expand-button-bg--stick: $brand-pear;

// Form wizard
$wizard-step-disabled-color: $brand-denim-2;
$wizard-step-active-color: $secondary;
$wizard-step-visited-color: $primary;
$wizard-step-icon-border-color: $wizard-step-disabled-color;
$wizard-step-icon-background-color: $brand-denim-4;
$wizard-step-icon-background-active-color: $brand-pear-2;
$wizard-step-line-color-sm: $line-color-main;
$wizard-step-line-color-lg: $brand-denim-3;
$wizard-step-title-color: $brand-denim;

// Modal
$modal-header-close-color: $secondary;
$modal-header-close-hover-color: $primary;


// Trigger Filters
$triggers-filter-action-link-color: $brand-denim-2;
$triggers-filter-action-link-hover-color: $text-color;
$triggers-filter-fixed-card-bg: $brand-sage-2;
$triggers-filter-fixed-lock-icon-color: $brand-sage-3;

// Chevron stages
$stage-chevron-bg: $brand-denim-6;
$stage-chevron-mob-bg: $brand-denim-5;
$stage-chevron-mob-active-bg: $brand-denim-3;
$stage-chevron-border-color: $line-color-main;
$stage-chevron-mob-active-border-color: $brand-denim-2;
$stage-chevron-color: $text-color;
$stage-chevron-icon-color: $brand-denim-1;
$stage-chevron-num-color: $text-color;
$stage-chevron-active-num-color: $text-color;

$ca-tabs-chevron-bg: $brand-denim-5;
$ca-tabs-chevron-active-bg: $brand-denim;
$ca-tabs-chevron-border-color: $brand-denim-3;
$ca-tabs-chevron-active-border-color: $brand-denim;
$ca-tabs-chevron-icon-color: $brand-denim-1;
$ca-tabs-chevron-active-icon-color: $brand-denim-5;
$ca-tabs-chevron-color: $text-color;
$ca-tabs-chevron-active-color: $text-inverse-color;
$ca-tabs-chevron-num-color: $text-color;
$ca-tabs-chevron-active-num-color: $brand-pear-1;


// Filter total
$widget-trigger-prediction-bg: $main-bg;
$widget-trigger-prediction-border-color: $line-color-main;
$widget-trigger-prediction-less-note-color: $danger;
$widget-trigger-prediction-more-note-color: $danger;
$widget-trigger-prediction-perfect-note-color: $primary;
$widget-trigger-prediction-less-header-color: $danger;
$widget-trigger-prediction-more-header-color: $danger;
$widget-trigger-prediction-perfect-header-color: $success;

// Project widgets
$widget-var-1-color: $brand-mulberry;
$widget-var-2-color: $brand-pear-5-cta;
$widget-var-3-color: $brand-graphite;

// Tags
$custom-tag-bg: $brand-denim-5;
$custom-tag-color: $brand-denim;
$custom-tag-fixed-bg: $brand-sage-2;
$custom-tag-icon-color: $brand-denim-1;
$custom-tag-hover-bg: $brand-denim-4;
$custom-tag-disabled-bg: $brand-denim-6;
$custom-tag-disabled-color: $brand-denim-3;

$tags-edit-error-bg: rgba($danger, .1);

$tag-prediction-bg: $primary;
$tag-prediction-bg-perfect: $brand-pear;
$tag-prediction-bg-too-much: $brand-mulberry;
$tag-prediction-bg-disabled: $brand-denim-4;

$icon-del-color: $brand-mulberry;
$icon-del-hover-color: $brand-error;

// Badges
$badge-bg: $secondary;
$badge-color: $text-inverse-color;
$badge-info-color: $brand-info-dark;
$badge-bg-secondary-light: $brand-pear-1;

// Map
$map-main-color: $primary;
$map-current-color: $secondary;
$map-updated-color: $brand-paris-green-3;

$map-marker-border: #586E81;
$map-marker-fill: $primary;

$map-new-border: #0B5885;
$map-new-fill: $brand-info;

$map-monitoring-border: #7B8100;
$map-monitoring-fill: $brand-pear;

$map-working-border: #38002D;
$map-working-fill: $brand-mulberry;

$map-updated-border: #4aa76c;
$map-updated-fill: #55c980;

$map-undesirable-border: #777A67;
$map-undesirable-fill: $brand-sage;

$map-deleted-border: $brand-mulberry-3;
$map-deleted-fill: $brand-mulberry;

// Pagination
$pagination-color: $text-shaded;
$pagination-hover-color: $primary;
$pagination-active-color: $brand-white;
$pagination-bg: $main-bg;
$pagination-hover-bg: $brand-denim-4;
$pagination-active-bg: $primary;
$pagination-border-color: $line-color-main;

// Progress pages
$progress-pages-bg: $secondary-bg;
$progress-pages-active-bg: $secondary;
$progress-btn-to-top-color: $secondary;
$progress-btn-to-top-hover-color: $text-inverse-color;
$progress-btn-to-top-hover-bg: $secondary;

// Table
$table-sort-icon-color: $secondary;
$table-row-success-even: rgba($success-bg, .5);
$table-row-success-odd: rgba($success-bg, .8);
$table-row-danger-even: rgba(#C48997, .1);
$table-row-danger-odd: rgba(#C48997, .15);

// Timeline
$timeline-note-panel-background-color: #FEFBF9;
$timeline-activity-overdue-background-color: #FEFBF9;
$timeline-activity-overdue-color: #C48997;
$timeline-activity-planed-color: #2DAD5C;
$timeline-activity-planed-background-color: $success-bg;

$timeline-activity-planed-badge-bg: $brand-pear-3;
$timeline-activity-planed-badge-border-color: $brand-pear-5-cta;
$timeline-activity-planed-badge-color: $brand-pear-6-cta;
$timeline-activity-overdue-badge-bg: $brand-mulberry;
$timeline-activity-overdue-badge-border-color: $brand-mulberry;
$timeline-activity-overdue-badge-color: $brand-white;
$timeline-activity-done-badge-bg: $brand-denim-5;
$timeline-activity-done-badge-border-color: $brand-graphite;
$timeline-activity-done-badge-color: $brand-graphite;

$activity-danger-color: $danger-secondary;
$activity-danger-bg: $brand-mulberry-2;

$timeline-activity-card-bg: $brand-denim-6;
$timeline-activity-card-border-color: $brand-denim-5;
$timeline-activity-card-bg-overdue: $activity-danger-bg;
$timeline-activity-card-border-color-overdue: $brand-mulberry-1;
$timeline-activity-card-title-color-overdue: $activity-danger-color;
$timeline-activity-card-title-line-color: $line-color-main;
$timeline-activity-card-title-line-color-overdue: lighten($brand-mulberry-1, 15%);

$checkbox-activity-status-bg: $main-bg;
$checkbox-activity-status-bg-done: $brand-denim-5;
$checkbox-activity-status-color: $brand-denim-4;
$checkbox-activity-status-color-done: $brand-denim;
$checkbox-activity-status-color-overdue: $brand-mulberry-1;

$activity-tab-border: $brand-denim-1;
$activity-tab-color: $brand-denim-1;
$activity-tab-color-hover: $brand-denim;
$activity-tab-color-active: $brand-white;
$activity-tab-bg: $brand-white;
$activity-tab-bg-active: $brand-denim-1;
$activity-tab-bg-hover: $brand-denim-5;

$activity-sub-tab-border: $brand-pear-1;
$activity-sub-tab-color: $brand-denim-1;
$activity-sub-tab-color-active: $brand-denim;
$activity-sub-tab-color-disabled: $brand-denim-2;


// Stages
$stage-color: $brand-denim;
$stage-bg: $brand-denim-5;
$stage-active-color: $brand-denim;
$stage-active-color-hover: $brand-white;
$stage-active-bg: $brand-pear-2;
$stage-active-bg-hover: $brand-pear-5-cta;
$stage-items-color: $brand-denim;
$stage-items-bg: $brand-pear-3;
$stage-items-border: $brand-pear-1;

// Status activities

// Notes
$note-section-border-bottom: rgba(220, 214, 221, 0.5);

$card-note-bg: $brand-sage-2;
$card-note-border-color: $brand-sage-1;
$card-note-separator-color: $brand-pear;
$card-note-controls-link-color: $brand-denim-3;
$card-note-controls-link-hover-color: $brand-denim;
$card-note-controls-link-del-color: $brand-mulberry-1;
$card-note-controls-link-del-hover-color: $brand-mulberry;
$card-note-linked-label-color: $brand-denim;
$card-note-btn-cancel-color: $brand-denim-1;

// Checkboxes related
$checkboxes-related-item-disabled-color: $text-muted;
$checkboxes-related-item-active-disabled-bg: rgba($brand-mulberry-2, .3);
$checkboxes-related-item-active-disabled-color: $brand-mulberry-1;
$checkboxes-related-item-disabled-title-color: $brand-mulberry;
$checkboxes-related-checked-bg: $brand-denim-5;
$checkboxes-related-active-bg: $brand-denim-4;
$checkboxes-related-hover-bg: $brand-denim-5;
$checkboxes-related-badge-bg: $brand-denim-1;
$checkboxes-related-active-disabled-hover-bg: rgba($brand-mulberry-2, .3);

// Checkboxes tree
$checkboxes-tree-trigger: $brand-pear-1;

// Search
$search-form-sep-border-color: $line-color-main;
$search-form-action-link-color: $brand-denim-2;
$search-form-action-link-hover-color: $text-color;
$search-form-apply-button-total-color: $brand-pear-1;
$search-btn-clear-color: $secondary;
$search-btn-save-search-disabled-color: $text-shaded;
$search-btn-save-search-disabled-border-color: $link-disable-color;
$search-dd-btn-text-font-weight: normal;
$search-dd-btn-text-transform: none;
$search-dd-btn-text-letter-spacing: normal;
$search-btn-reset-danger-color: $brand-mulberry-4;
$search-btn-reset-danger-bg-color: $brand-mulberry-2;
$search-btn-reset-danger-hover-bg-color: darken($brand-mulberry-2, 7.5%);
$search-btn-reset-secondary-color: $brand-pear-4;
$search-btn-reset-secondary-bg-color: $brand-pear-3;
$search-btn-reset-secondary-hover-bg-color: darken($brand-pear-3, 7.5%);

// Templates
$template-btn-save-color: $secondary;
$template-btn-save-hover-color: $btn-color;
$template-btn-save-hover-bg: $secondary;
$template-btn-save-focus-shadow: $secondary-focus-shadow-color;
$template-btn-save-active-shadow: $secondary-active-shadow-color;

// Autocomplete
$autocomplete-item-color: $primary;
$autocomplete-item-label-color: $text-muted;
$autocomplete-item-group-color: $text-muted;
$autocomplete-item-group-border: $line-color-main;
$autocomplete-item-hover-bg: $brand-denim-5;
$autocomplete-item-selected-bg: $secondary-bg;
$autocomplete-input-disabled-color: $brand-denim-6;

// Table simple
$table-simple-cell-bg: $brand-denim-6;
$table-simple-cell-border: $line-color-dark;

// Scroll bar
$scrollbar-track-color: $brand-denim-5;
$scrollbar-thumb-color: $brand-denim-2;

// Setup assistant
$set-assistant-color: $brand-denim;
$set-assistant-muted-color: $brand-denim-3;

// Custom radios
$radio-button-on-label-bg: $secondary-bg;
$radio-button-on-dot-color: $primary;
$radio-button-on-label-color: $primary;
$radio-button-label-color: $brand-denim-1;
$radio-button-dot-color: $brand-denim-2;
$radio-button-hover-label-bg: $brand-denim-5;
$radio-button-secondary-bg: lighten($brand-sage-1, 7.5%);
$radio-button-secondary-hover-bg: $brand-sage-1;
$radio-button-secondary-color: $brand-pear-4;

// More options
$more-options-nav-color: $text-shaded;
$more-options-nav-active-color: $text-color;
$more-options-nav-active-bg: $brand-denim-5;
$more-options-check-active-bg: $brand-denim-6;

// Alerts
$alert-primary-bg: rgba($primary, .1);
$alert-secondary-bg: rgba($secondary, .1);
$alert-success-bg: rgba($success, .1);
$alert-info-bg: rgba($info, .1);
$alert-warning-bg: $brand-mulberry-2;
$alert-danger-bg: rgba($danger, .1);

// Calendar, Date Picker
$dp-highlight-bg: $brand-pear;
$dp-highlight-color: $brand-white;
$dp-range-bg: $brand-pear-3;
$dp-today-bg: $brand-mulberry;
$dp-today-hover-bg: $brand-mulberry-1;
$dp-today-color: $brand-white;
$dp-hover-bg: rgba($brand-denim, .2);
$dp-hover-color: $text-color;

$calendar-today-bg: $brand-mulberry;
$calendar-arrow-bg: $brand-pear-5-cta;
$calendar-arrow-bg-hover: $brand-pear-6-cta;
$calendar-event-color: $brand-denim;
$calendar-event-bg: rgba($brand-denim-5, .3);
$calendar-event-color-past: $brand-denim-2;
$calendar-event-bg-past: rgba($brand-denim-5, .6);
$calendar-event-color-overdue: $brand-mulberry;
$calendar-event-bg-overdue: rgba($brand-mulberry-2, .8);
$calendar-event-color-today: $brand-pear-5-cta;
$calendar-event-bg-today: $brand-pear-3;

$calendar-grid-text: $brand-denim-1;
$calendar-grid-main: $brand-denim-4;
$calendar-grid-secondary: $brand-denim-5;
$calendar-line-now: $secondary;


// Company Analysis
$ca-overview-widget-border: $brand-denim-4;
$ca-overview-widget-text: $brand-denim;

$ca-widget-companies-bg: $brand-graphite-1;
$ca-widget-companies-line: $brand-graphite;
$ca-widget-companies-text: $brand-graphite;
$ca-widget-projects-bg: $brand-mulberry-2;
$ca-widget-projects-line: $brand-mulberry;
$ca-widget-projects-text: $brand-mulberry;
$ca-widget-value-bg: $brand-pear-3;
$ca-widget-value-line: $brand-pear;
$ca-widget-value-text: $brand-pear-6-cta;

$ca-expandable-bg: $brand-denim-6;
$ca-expandable-active-bg: $brand-denim-5;
$ca-expandable-border-color: $brand-denim-6;
$ca-expandable-active-border-color: $brand-denim-4;
$ca-expandable-color: $brand-denim-1;
$ca-expandable-active-color: $brand-denim;
$ca-expandable-arrow-color: $brand-pear-5-cta;
$ca-expandable-active-arrow-color: $brand-pear-6-cta;
$ca-expandable-num-color: $brand-denim;
$ca-expandable-active-num-color: $brand-denim;

// User select
$user-select-bg: $secondary-bg;

// System update btn
$system-update-button-bg: $brand-mulberry;
$system-update-button-border-color: $brand-mulberry-1;

// PWA history nav
$history-nav-btn-bg: $secondary;
$history-nav-btn-bg-hover: $btn-secondary-active-bg;
$history-nav-btn-mob-bg: $brand-denim-1;
$history-nav-btn-mob-bg-hover: $secondary;

//Usage statistics
$us-widget-positive-color: $brand-pear-5-cta;
$us-widget-positive-active-color: $brand-pear-6-cta;
$us-widget-positive-color-arrow: $brand-pear-5-cta;
$us-widget-negative-color: $brand-mulberry;
$us-widget-negative-active-color: $brand-mulberry;
$us-widget-negative-color-arrow: $brand-mulberry;
$us-widget-zero-color: $brand-graphite;
$us-widget-zero-active-color: $brand-denim;
$us-widget-active-bg: $brand-denim-4;
$us-widget-active-border: $brand-denim-3;
$us-widget-active-p-bg: $brand-pear-3;
$us-widget-active-p-border: $brand-pear-2;
$us-widget-active-n-bg: $brand-mulberry-2;
$us-widget-active-n-border: $brand-mulberry-1;

// Privacy popup
$privacy-popup-bg: rgba($brand-denim, 0.7);
$privacy-popup-color: $brand-white;

// Buttons
$button-outline-disabled-color: $brand-denim-3;
$button-outline-disabled-border: $brand-denim-4;

// Tooltips
$tooltip-bg-color: $primary;
$tooltip-text-color: $brand-denim-6;
$tooltip-link-color: $brand-pear-1;
$tooltip-border-color: $primary;
$tooltip2-bg-color: $brand-pear-3;
$tooltip2-text-color: $text-color;
$tooltip2-link-color: $secondary;
$tooltip2-border-color: $brand-pear-1;

// User status tags
$status-tag-disabled-bg: $brand-sage-3;
$status-tag-active-bg: $secondary;
$status-tag-expired-bg: $text-muted;
$status-tag-suspended-bg: $warning;
$status-tag-completed-bg: #218380;
$status-tag-queue-bg: $brand-denim-1;

// Contacts
$company-contact-inactive-color: $brand-sage;

// Select control
$select-control-option-hover-bg: $secondary-bg;
$select-control-option-hover-color: $text-color;
$select-control-option-color: $text-shaded;

// Form sections
$form-section-border-color: $brand-denim-3;

// Login as user
$logged-as-user-body-border: $brand-mulberry-3;
$logged-as-user-body-bg: #f1eef4;

// Update notification
$update-notification-icon: $brand-denim-1;
$update-notification-icon-hover-bg: $brand-denim-4;
$update-notification-icon-active: $brand-denim;
$update-notification-icon-active-bg: $brand-denim-4;
$update-notification-icon-active-hover-bg: $brand-denim-3;
$update-notification-icon-active-indicator: $danger;

// Progress bar
$progress-bar-secondary-bg: $brand-pear-3 linear-gradient(135deg, #{$brand-pear-3} 0%, #{$brand-pear-3} 33.32%, rgba($secondary, .3) 33.33%, rgba($secondary, .3) 66.65%, #{$brand-pear-3} 66.66%, #{$brand-pear-3} 100%) 0 0/14px 8px repeat-x;
$progress-bar-secondary-active: $secondary;
$progress-bar-danger-bg: rgba($danger, .1) linear-gradient(135deg, #{rgba($danger, .1)} 0%, #{rgba($danger, .1)} 33.32%, rgba($danger, .4) 33.33%, rgba($danger, .4) 66.65%, #{rgba($danger, .1)} 66.66%, #{rgba($danger, .1)} 100%) 0 0/14px 8px repeat-x;
$progress-bar-danger-active: $danger;

// File types
$file-doc-color: $brand-info-dark;
$file-pdf-color: $brand-error;
$file-xls-color: $brand-paris-green-3;
$file-eml-color: $brand-info;
$file-ppt-color: #BC321D;
$file-default-color: $secondary;
$file-error-color: $danger;
$file-png-color: $brand-paris-green-3;
$file-jpg-color: #FF8A00;

// Mobile Activities Card
$activity-card-icon-color: $brand-denim-1;
$activity-card-planned-today-bg: $brand-pear-3;
$activity-card-planned-today-border: $brand-pear-1;
$activity-card-done-bg: $brand-denim-5;
$activity-card-done-border: $brand-denim-3;
$activity-card-done-color: $brand-denim-1;
$activity-card-overdue-bg: $brand-mulberry-2;
$activity-card-overdue-border: $brand-mulberry-1;
$activity-card-overdue-color: $brand-mulberry;

// Charts
$chart-main-font: $font-main;
$chart-nums-font: $font-heading;
$chart-main-color: $brand-denim;
$chart-projects-color: $brand-mulberry;
$chart-projects-hover-color: $brand-mulberry-3;
$chart-costs-bg: $brand-pear-1;
$chart-costs-color: $brand-pear-5-cta;
$chart-costs-data-label-color: $brand-denim;
$chart-costs-hover-color: $brand-pear-2;
$usage-stats-chart-current-text-color: $brand-pear-5-cta;
$usage-stats-chart-current-line-color: $brand-pear;
$usage-stats-chart-previous-text-color: $brand-mulberry;
$usage-stats-chart-previous-line-color: $brand-mulberry-1;
$usage-stats-chart-gradient-color: rgba($brand-pear-2, 0);
$usage-stats-chart-gradient-color-opacity: rgba($brand-pear-2, .8);

// Entity status tag
$entity-status-tag-default-color: $brand-graphite;
$entity-status-tag-default-bg: #E7E8E9;
$entity-status-tag-active-color: $brand-pear-4;
$entity-status-tag-active-bg: $brand-pear-3;
$entity-status-tag-deactivated-color: $brand-mulberry;
$entity-status-tag-deactivated-bg: $brand-mulberry-2;
$entity-status-tag-muted-color: $brand-denim-2;
$entity-status-tag-muted-bg: $brand-denim-5;

// Client user status
$client-user-status-not-invited: $brand-sage-3;
$client-user-status-invited: $brand-graphite;
$client-user-status-active: $brand-pear-4;
$client-user-status-muted: #BBBDC0;

$header-notif-banner-warning-color: $brand-mulberry-2;

// Entity title
$entity-title-color: $brand-denim;
$entity-title-visited-color: $brand-denim-1;
$entity-title-hover-color: $brand-pear-5-cta;
$deleted-entity-title-color: $brand-mulberry;
$deleted-entity-title-visited-color: $brand-mulberry-3;
$locked-entity-title-color: $brand-graphite;
$locked-entity-title-visited-color: #888F96;
$archived-tender-title-color: #616354;
$archived-tender-title-visited-color: $brand-sage;
$archived-tender-badge-bg: $brand-sage;

// custom fields
$custom-field-input-disabled: $brand-denim-3;

// Table Responsible
$responsible-table-inactive-color: $brand-denim-3;

// Badge role
$badge-late-role-bg: #935985;

// Input Autocomplete Selector
$ia-selector-color: $brand-pear-5-cta;
$ia-selector-bg: transparent;
$ia-selector-color-hover: #666713;
$ia-selector-bg-hover: $brand-pear-3;
