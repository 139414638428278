@import '../variables';
@import '../mixins';

.entity-list {
  $block: &;

  &.is-cards-view {
    background: none;
    border: 0;
    box-shadow: none;
  }

  //.base-card__body > & {
  //  margin-right: -15px;
  //  margin-left: -15px;
  //  margin-bottom: -15px;
  //
  //  .table-responsive {
  //    margin-bottom: 0;
  //  }
  //}

  .card {
    background-color: var(--tabs-main-bg, $tabs-main-bg);
    border: var(--tabs-main-border, $tabs-main-border);
    box-shadow: var(--tabs-main-shadow, $tabs-main-shadow);

    &.has-progress {
      margin-bottom: 70px;
    }

    & > .card-header {
      background-color: var(--main-bg, $main-bg);
      border-bottom-color: var(--entity-list-header-line-color, $entity-list-header-line-color);
    }
  }

  .is-cards-view & .tab-pane {
    padding: 0;
  }

  &.is-cards-view > .card {
    background: none;
    border: 0;
    box-shadow: none;

    & > .card-header {
      // background-color: var(--main-bg, $main-bg);
      // border: var(--tabs-main-border, $tabs-main-border);
      // box-shadow: var(--tabs-main-shadow, $tabs-main-shadow);
      // margin-bottom: 10px;
      // border-radius: var(--border-radius, $border-radius);
      background-color: transparent;
      border-bottom: 0;
      padding: 0;
    }
  }

  &.is-table-view .progress-container {
    margin-left: -13px;
  }

  &__progress-container {
    position: fixed;
    z-index: 1000;
    bottom: 0;
  }

  &__header {
    //@include media-breakpoint-up(md) {
    //  padding-bottom: 0;
    //}

    //#{$block}--bordered &,
    //.tab-content & {
    //  #{$block}__header {
    //    padding: 12px 16px;
    //
    //    @include media-breakpoint-up(md) {
    //      padding-bottom: 2px;
    //    }
    //  }
    //}
  }

  &.is-main.is-cards-view {
    //#{$block}__header {
    //
    //
    //  @include media-breakpoint-down(sm) {
    //    flex-wrap: wrap;
    //  }
    //}

    #{$block}__body {
      padding: 0;
    }
  }

  &__body {
    padding: 8px 0 0;

    .is-view-list & {
      padding-top: 0;
    }
  }

  &__pagination {
    display: flex;
    margin-top: 0;
    padding: 24px 8px 0;
    flex-wrap: wrap;

    .is-cards-view & {
      padding: 16px 0 0;
      margin-top: 12px;
    }

    .pagination {
      margin: 0 auto;
    }

    .pagination-help {
      padding-left: 4px;
      font-size: 14px;
      line-height: 16px;
      margin-right: 15px;
      margin-bottom: 8px;
      flex: 1;
      min-width: 0;

      &__link {
        margin-top: 5px;
      }

      @include media-breakpoint-down(sm) {
        flex: none;
        min-width: auto;
        width: 100%;
        text-align: center;
        margin: 0 0 16px;
      }
    }

    .is-cards-view & .pagination-help {
      padding-left: 0;
    }

    &.pagination-collapsed {
      .btn-collapse {
        margin-left: auto;
        min-width: 160px;

        &__text {
          margin-right: auto;
        }

        &__icon {
          margin-left: 10px;
          color: var(--entity-list-button-collapse-icon-color, $entity-list-button-collapse-icon-color);
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__pagination {
      .pagination {
        margin-right: 0;
      }
    }
  }

  .column-checked {
    .custom-control {
      padding-left: 16px;
    }

    .custom-control-label {
      &::before,
      &::after {
        left: -16px;
      }
    }
  }

  &--bordered {
    border: var(--tabs-main-border, $tabs-main-border);
    box-shadow: var(--tabs-main-shadow, $tabs-main-shadow);
    background-color: var(--tabs-main-bg, $tabs-main-bg);
    margin-bottom: 24px;
  }

  &--bordered,
  .tab-content & {
    /*
    #{$block}__header {
      padding: 12px 16px;

      @include media-breakpoint-up(md) {
        padding-bottom: 2px;
      }
    }
    */

    #{$block}__body {
      padding: 8px;
    }

    #{$block}__pagination {
      margin-bottom: 8px;
    }
  }
}

.view-cards {
  &__header {
    height: 100px;
    overflow: hidden;
  }

  &__sort {
    background: $light;
    padding: 10px 25px;
    margin-bottom: 25px;
  }

  &__sort-asc {
    width: 90px;
    display: flex;
    align-items: center;
  }

  &__sort-asc-link {
    color: #9ea8b1;

    &:hover, &.active {
      color: #fff;
    }
  }

  &__sort-select {
    max-width: 330px;
  }

  &__list-icon {
    text-align: center;
  }

  &__list-icon, &__list-text {
    display: inline-block;
    vertical-align: middle;
  }

  &__checked label {
    display: none !important;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer-left {
    display: flex;
    align-items: center;
  }

  &__footer-left > div,
  &__footer-right > div {
    line-height: 1;
  }
}

.btn-circle {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
}

.progress-container {
  padding: 0 4px 20px;
  background: var(--secondary-bg, $secondary-bg);
  margin-left: -5px;

  &__body {
    padding: 7px 15px;
    background-color: var(--tabs-main-bg, $tabs-main-bg);
    border: var(--tabs-main-border, $tabs-main-border);
    box-shadow: var(--tabs-main-shadow, $tabs-main-shadow);
    border-radius: var(--border-radius, $border-radius);
    display: flex;
    align-items: center;
  }

  &__info {
    font-size: 12px;
  }

  &__progress {
    flex: 1 1;
    padding: 0 20px;
  }

  .progress-btn-top {
    &.btn {
      width: 25px;
      height: 25px;
      min-height: 25px;
      padding: 0;
      border-radius: 25px;
      color: var(--progress-btn-to-top-color, $progress-btn-to-top-color);
      border-color: var(--progress-btn-to-top-color, $progress-btn-to-top-color);
      box-shadow: none;

      &:hover {
        color: var(--progress-btn-to-top-hover-color, $progress-btn-to-top-hover-color);
        background-color: var(--progress-btn-to-top-hover-bg, $progress-btn-to-top-hover-bg);
        border-color: var(--progress-btn-to-top-color, $progress-btn-to-top-color);
      }
    }

    .svg-icon {
      display: block;
      margin: 0;
    }
  }
}

.inner-projects-list-card {
  .filter-constr-start {
    .dropdown-menu {
      width: 370px;
      max-width: calc(100vw - 20px);
    }
  }

  .filter-constr-cost {
    .dropdown-menu {
      width: 370px;
      max-width: calc(100vw - 20px);
    }
  }
}

.entity-name {
  font-weight: bold;

  .entity-list:not(.is-user-section) &:visited,
  &--viewed {
    color: var(--text-muted, $text-muted);
  }
}

