@import "~@/assets/scss/variables";










































































































































































































.searchable-list {
  &__header {
    padding: 5px 3px 10px;
  }

  &__body {
    padding-top: 8px;
    border-top: 1px solid var(--line-color-main);
  }

  &__not-found {
    padding: 5px 10px;
  }

  &__list {
    overflow: auto;
    max-height: 240px;
    padding-right: 4px;
  }
}
