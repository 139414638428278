@import "~@/assets/scss/variables";











































































































.collapsible-list-item {
  background-color: var(--ca-expnd-bg);
  border-bottom: 1px solid var(--line-color-main);
  transition: 0.3s ease-in-out;
  transition-property: background, border;
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  &__body {
    flex: 1;
    min-width: 0;
  }

  &__selector {
    padding: 8px;
  }
}

.item-info {
  &__header {
    padding: 9px 8px;
    display: flex;

    .collapsible-list-item--collapsible & {
      cursor: pointer;
    }
  }

  &__icon {
    color: var(--secondary);
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    transition-property: transform, color;
    opacity: 0;

    .svg-icon {
      width: 12px;
      height: 12px;
    }

    .collapsible-list-item--visible & {
      transform: rotate(90deg);
    }

    .collapsible-list-item--collapsible & {
      opacity: 1;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    align-self: center;
    display: flex;
    font-weight: 500;
  }

  &__content {
    margin-bottom: 30px;
    padding: 0 12px;
  }
}
