@import "~@/assets/scss/variables";




























































































.client-option {
  &__source-id,
  &__smart-id {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-shaded);
  }
}

.selected-client {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__name {
    font-size: 14px;
    line-height: 16px;
  }

  &__source-id,
  &__smart-id {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-shaded);
  }

  &__name,
  &__source-id,
  &__smart-id {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
