@import "~@/assets/scss/variables";











































































































































































.v-date-picker {
  &::v-deep .vc-popover-content-wrapper {
    z-index: 11;

    @media (max-height: 450px) {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;

      .vc-popover-caret {
        display: none;
      }
    }
  }

  &--disabled {
    pointer-events: none;

    .vdp-control {
      &__icon {
        color: var(--input-color-disabled);
      }
    }
  }
}

.vdp-control {
  position: relative;
  display: block;
  margin-bottom: 0 !important;
  color: var(--input-color) !important;

  &__icon {
    position: absolute;
    width: 30px;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
    color: currentColor;

    .svg-icon {
      display: block;
      width: 16px;
      height: 16px;
    }
  }

  &__input {
    padding-left: 30px;
    padding-right: 24px;
    font-size: 14px;
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 22px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border: solid currentColor;
      border-width: 0 1px 1px 0;
      margin-top: -4px;
      transform: rotate(45deg);
      opacity: .7;
    }
  }

  .v-date-picker--invalid & {
    --input-color: var(--warning);
    --input-bg: var(--input-invalid-bg);
    --input-focus-bg: var(--input-invalid-bg);
    --input-border-color: var(--input-invalid-border-color);
    --input-focus-border-color: var(--input-invalid-focus-border-color);
  }

  .v-date-picker--custom-field &__icon {
    right: 4px;
    left: auto;
    width: auto;
    padding: 0 5px;
  }

  .v-date-picker--custom-field &__arrow,
  .v-date-picker--invalid &__arrow,
  .v-date-picker--valid &__arrow {
    display: none;
  }

  .v-date-picker--custom-field &__input {
    padding-left: 8px;
  }

  .v-date-picker--custom-field.is-valid &__icon,
  .v-date-picker--custom-field.is-invalid &__icon,
  .v-date-picker--custom-field.wait &__icon {
    display: none;
  }
}
