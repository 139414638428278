@import "~@/assets/scss/variables";


























.id-link-field {
  display: flex;

  &__text {
    margin-right: 4px;
  }
}
