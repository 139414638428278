@import "~@/assets/scss/variables";



















































.copied-text {
  display: flex;

  &--always-show-copy &__icon {
    opacity: 1;
  }

  &:hover &__icon {
    opacity: 1;
  }

  &__text {
    word-break: break-all;

    &::v-deep a {
      display: block;
    }
  }

  &__icon {
    opacity: 0;
    transition: opacity .5s;
    margin-left: 12px;

    &__link {
      color: var(--secondary);
    }

    .svg-icon {
      width: 16px;
      height: 16px;
    }
  }
}
