@import "~@/assets/scss/variables";















































































































































































































.select-dropdown-search {
  &.is-invalid::v-deep .dropdown-toggle {
    border-color: var(--input-border-error-color, $input-border-error-color);
  }

  &__dropdown {
    width: 100%;
  }

  &::v-deep {
    .dropdown-menu {
      width: 350px;
      min-width: 100%;
      max-width: calc(100vw - 20px);
    }

    .b-dropdown-text {
      padding: 5px;
    }

    .dropdown-toggle {
      &__icon {
        margin-top: -4px;
        margin-bottom: -4px;
      }
    }

    .searchable-list__list {
      overflow: auto;
      max-height: 240px;
      padding-right: 4px;
    }

    .c-avatar {
      --size: 24px;
    }
  }
}
