@import "~@/assets/scss/variables";
























































































































































































































@import '~@/assets/scss/_mixins.scss';

.entity-list-header {
  $block: &;

  //position: relative;
  z-index: 10;
  padding: 12px 0 0 0;

  .entity-list.is-main & {
    padding: 8px 8px 0 8px;
  }

  .entity-list.is-cards-view & {
    position: sticky;
    //top: $navbar-height;
    top: 0;
    background: var(--main-bg);
  }

  .entity-list.is-main.is-cards-view & {
    background-color: var(--main-bg);
    border: var(--tabs-main-border);
    box-shadow: var(--tabs-main-shadow);
    border-radius: var(--border-radius);
    margin-bottom: 10px;
    padding: 8px;
  }

  .entity-list.is-loading &__operations {
    pointer-events: none;
    opacity: .65;
  }

  &__body {
    min-height: 49px;
    padding: 4px 8px 12px 8px;
    border-bottom: 1px solid var(--entity-list-header-line-color);
    display: flex;
    width: 100%;

    &--bulk-operations {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: var(--secondary-bg);
    }

    .entity-list.is-main.is-cards-view & {
      padding: 8px;
      border-bottom: 0;
    }
  }

  &__content {
    margin-bottom: -10px;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }

  &__operations {
    display: flex;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      //margin-bottom: 10px;

      &.wrapped {
        flex-grow: 1;
        min-width: 0;

        & > *:not(.cards-sort):first-child {
          flex-grow: 1;
        }
      }
    }
  }
}
