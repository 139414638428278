@import "~@/assets/scss/variables";








































































































































































.entity-list-total-number {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  display: flex;

  @include media-breakpoint-up(md) {
    padding-right: 32px;
    margin-right: auto;
    margin-bottom: 10px;
    width: auto;
    flex: 1 0 auto;
  }

  &__body {
    margin-left: -24px;
    margin-bottom: -4px;
    min-height: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    margin-left: 24px;
    margin-bottom: 12px;
    visibility: hidden;
    opacity: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 4px;
    }

    &.visible {
      visibility: visible;
      opacity: 1;
      transition: opacity .3s;
    }
  }

  b {
    font-weight: bold;

    &.total-amount {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.locked-total {
  --text-color: var(--text-shaded);

  display: inline-flex;
  align-items: center;

  &.is-shown-locked {
    --text-color: var(--secondary);
  }

  &__icon {
    color: var(--text-color);
    display: inline-block;
    margin-top: -4px;
    margin-right: 4px;
    vertical-align: middle;
  }

  &__text {
    color: var(--text-color);
    margin-left: 6px;
  }
}

.clear-filter-link {
  color: var(--link-secondary-hover-color);
  text-decoration: underline;

  &:hover {
    color: var(--secondary);
    //text-decoration: none
  }
}

.all-total-spinner {
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 2px;
}
