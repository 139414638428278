@import "~@/assets/scss/variables";





















































































































.entity-list-view {
  position: relative;
  min-height: 100px;

  &__loader {
    position: absolute;
    background-color: rgba(255, 255, 255, .6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;

    .loading {
      position: sticky;
      top: calc(50% - 20px);
    }
  }
}

::v-deep .entity-row {
  --border-unviewed-color: transparent;

  >td:first-child {
    &::before {
      --height: 87%;
      content: '';
      position: absolute;
      top: calc((100% - var(--height)) / 2);
      left: 0;
      width: 3px;
      height: var(--height);
      background-color: var(--border-unviewed-color);
    }
  }

  &--unviewed {
    --border-unviewed-color: var(--primary);
  }
}
